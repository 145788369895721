<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="title-block">{{$t('message.evaluation')}}</div>
      <div>
        <el-button class="my-btn " type="warning" @click="take" round>
          {{$t('message.send_evaluation')}}
        </el-button>
         <router-link :to="{ name: 'my_evaluation' }">
          <div class="my-btn ml20 cr1 el-button">
            <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
          </div>
        </router-link>
      </div>
    </div>
    
    <div class="content-block">
      <div class="datetime-end bg p20 mb20">
        <el-divider content-position="left"> {{$t('message.manager')}}</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="seloction mb20">
              <el-select
                v-model="form.manager_id"
                :placeholder="$t('message.manager')"
                class="w-100"
                filterable
                clearable
                popper-class="my-data-khan"
              >
                <el-option
                   v-for="(staff, index) in list"
                  :key="'staffs-' + index"
                  :value="staff.id"
                  :label="staff.name + ' ' + staff.last_name"
                ></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="comment bg p20 mb20">
        <el-divider content-position="left"> {{$t('message.evaluation')}} </el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb-3" style="margin-bottom:20px">
            <!-- <el-input
              :placeholder="$t('message.title')"
              v-model="form.header"
              class="mb20"
            ></el-input> -->
           
            <star-rating v-model="form.rating" :min-rating="1"  :max-rating="10" :rating="3"  :star-size="35"></star-rating>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input type="textarea" v-model="form.comment" :placeholder="$t('message.comment')"></el-input>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import StarRating from 'vue-star-rating'
import { mapGetters, mapActions } from "vuex";
export default {
  // props: ["id"],
 
  data() {
    return {
      region: "",
      value1: "",
      value: "",
      form: {
        rating:5
      },
      list: [],
    };
  },
  components:{
    StarRating
  },
  mounted() {
    this.setList();
  },
  methods: {
    ...mapActions({
      updateList: "evaluation/store",
      getList: "assent/managers",
    }),
    setList() {
      this.getList(getItem("userId")).then((res) => {
        this.list = res.data.result.data.managers;
      });
    },
    take() {
      this.form.staff_id = getItem("userId");
      this.updateList(this.form)
        .then((res) => {
          if (res.status == 201) {
            this.$alert(res);
            let route = this.$router.push({
              name: "Home",
              params: {
                id: this.list.id,
              },
            });
            window.open(route.href, "_self");
          } else {
            this.$alert(res);
          }
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>
<style lang="scss">
.vue-star-rating-rating-text{
  color: rgb(205, 205, 205)
}
</style>
